<template>
  <div style="height: 100%">
    <div class="checklist-edit">
      <div class="step-box">
        <head-layout :show-icon="false" head-title="处理进度"></head-layout>
        <el-steps direction="vertical" :active="active" style="margin-top: 30px;align-items: center;height: 50%;">
          <el-step title="上报"></el-step>
          <el-step title="评估"></el-step>
          <el-step title="整改"></el-step>
          <el-step title="验收"></el-step>
        </el-steps>
      </div>
      <div class="form-box">
        <div v-if="prepare" class="">
          <head-layout head-title="查看隐患信息"
                       :head-btn-options="[{ label: '取消', emit: 'head-cancel', type: 'button', btnOptType: 'cancel'}]"
                       @head-cancel="headCancel"></head-layout>
          <div class="formContentBox">
            <div class="formMain">
              <div style="padding: 0 12px">
                <el-form ref="dataForm" :model="dataForm" label-width="150px" disabled>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="隐患编号" prop="hdCode">
                        <el-input v-model="dataForm.hdCode" disabled placeholder="请输入隐患编号"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="隐患地点（经纬度）" prop="prjName">
                        <el-input  v-model="dataForm.location"
                                  placeholder="请填写隐患地点（经纬度）"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="报告人" prop="reportUserName">
                        <el-input v-model="dataForm.reportUserName" placeholder="请填写报告人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="报告部门" prop="reportDeptName">
                        <el-input v-model="dataForm.reportDeptName" placeholder="请填写报告部门"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="报告时间" prop="reportDate">
                        <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm.reportDate" placeholder="请选择报告时间"></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="隐患描述" prop="hdDesc">
                        <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit v-model="dataForm.hdDesc"
                                  placeholder="请填写隐患描述"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="隐患来源" prop="sourceType">
                        <avue-select :props="{
              label: 'dictValue',
              value: 'dictKey',
            }" v-model="dataForm.sourceType" placeholder="请选择隐患来源" type="tree"
                                     :dic="HD_SOURCE_TYPE"></avue-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="隐患现场" prop="prjAs">
                        <div class="content">
                          <div
                            v-for="(item, index) in treeData"
                            :key="item.id"
                            class="img-content"
                            @click="getNodeClick(item)"
                          >
                            <img
                              v-if="
                          item.extension == 'jpg' ||
                          item.extension == 'png' ||
                          item.extension == 'image/jpeg' ||
                          item.extension == 'image/png'
                        "
                              :src="item.link"
                              alt=""
                            />
                            <img
                              v-else
                              src="@/assets/images/dashboard-nodata.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div v-if="unassess" class="">
          <head-layout head-title="查看隐患评估"></head-layout>
          <div class="formContentBox">
            <div class="formMain">
              <div style="padding: 0 12px">
                <el-form ref="dataForm" :model="dataForm1" label-width="150px" disabled>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="隐患核实" prop="passFlag">
                        <el-radio v-model="dataForm1.passFlag" label="y">核实通过</el-radio>
                        <el-radio v-model="dataForm1.passFlag" label="n">核实不通过</el-radio>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="隐患等级" prop="prjName">
                        <avue-select :props="{
              label: 'dictValue',
              value: 'dictKey',
            }" v-model="dataForm1.hdLevel" placeholder="请选择隐患等级" type="tree" :dic="hd_level"></avue-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="隐患类型" prop="hdType">
                        <avue-select :props="{label: 'dictValue',value: 'dictKey'}" v-model="dataForm1.hdType"
                                     placeholder="请选择隐患类型" type="tree" :dic="hd_type"></avue-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="责任部门" prop="respDeptName">
                        <el-input v-model="dataForm1.respDeptName" placeholder="请选择责任部门"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="责任人" prop="respUserName">
                        <el-input v-model="dataForm1.respUserName" placeholder="请选择责任人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="提交整改计划" prop="rectifyPlanFlag">
                        <el-checkbox false-label="n" true-label="y" v-model="dataForm1.rectifyPlanFlag"></el-checkbox>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="整改部门" prop="rectifyDeptName">
                        <el-input v-model="dataForm1.rectifyDeptName" placeholder="请选择整改部门"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="整改人" prop="sourceType">
                        <el-input v-model="dataForm1.rectifyUserName" placeholder="请选择实际整改人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'n' || dataForm1.passFlag == 'y'">
                      <el-form-item label="评估时间" prop="assessmentTime">
                        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm1.assessmentTime"
                                        placeholder="请选择评估时间"></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="验收部门" prop="acceptDeptName">
                        <el-input v-model="dataForm1.acceptDeptName" placeholder="请选择验收部门"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="验收人" prop="acceptUserName">
                        <el-input v-model="dataForm1.acceptUserName" placeholder="请选择验收人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="整改期限" prop="rectifyLimitDate">
                        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm1.rectifyLimitDate"
                                        placeholder="请选择整改期限"></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="整改要求" prop="rectifyRequest">
                        <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit
                                  v-model="dataForm1.rectifyRequest" placeholder="请填写整改要求"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="dataForm1.passFlag == 'n'">
                      <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit v-model="dataForm1.remark"
                                  placeholder="请填写整改要求"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row v-if="dataForm1.passFlag == 'y'">
                    <el-col :span="24">
                      <el-form-item label="整改通知书" prop="prjAs">
                        <el-upload disabled class="upload-demo" :file-list="treeData1" :on-preview="handlePreview"></el-upload>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="处罚通知书" prop="prjAs">
                        <el-upload disabled :on-preview="handlePreview" class="upload-demo" :file-list="treeData2"></el-upload>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div v-if="unrectify" class="">
          <head-layout head-title="查看隐患整改"></head-layout>
          <div class="formContentBox">
            <div class="formMain">
              <div style="padding: 0 12px">
                <el-form ref="dataForm" :model="dataForm2" label-width="150px" disabled>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="完成时间" prop="rectifyDate">
                        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm2.rectifyDate"
                                        placeholder="请选择完成时间"></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="实际整改人" prop="actualRectifyUserName">
                        <el-input v-model="dataForm2.actualRectifyUserName" placeholder="请选择实际整改人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="整改措施" prop="remark">
                        <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit v-model="dataForm2.remark"
                                  placeholder="请填写整改要求"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="整改计划" prop="prjAs">
                        <el-upload
                          :on-preview="handlePreview"
                          disabled
                          class="upload-demo"
                          accept=".rar,.zip,.doc,.docx,.pdf"
                          :file-list="treeData3"></el-upload>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="整改结果" prop="prjAs">
                        <div class="content">
                          <div
                            v-for="(item, index) in treeData4"
                            :key="item.id"
                            class="img-content"
                            @click="getNodeClick(item)"
                          >
                            <img
                              v-if="
                          item.extension == 'jpg' ||
                          item.extension == 'png' ||
                          item.extension == 'image/jpeg' ||
                          item.extension == 'image/png'
                       " :src="item.link"   alt=""
                            />
                            <img
                              v-else
                              src="@/assets/images/dashboard-nodata.png"
                              alt=""
                            />

                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div v-if="finished" class="">
          <head-layout head-title="查看隐患验收"></head-layout>
          <div class="formContentBox">
            <div class="formMain">
              <div style="padding: 0 12px">
                <el-form ref="dataForm3" :model="dataForm3" label-width="150px" disabled>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="验收结果" prop="acceptFlag">
                        <el-radio v-model="dataForm3.acceptFlag" label="y">核实通过</el-radio>
                        <el-radio v-model="dataForm3.acceptFlag" label="n">核实不通过</el-radio>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="验收时间" prop="acceptDate">
                        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm3.acceptDate"
                                        placeholder="请选择完成时间"></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="实际验收人" prop="actualAcceptUserName">
                        <el-input v-model="dataForm3.actualAcceptUserName"
                                  placeholder="请选择实际验收人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="验收参与人" prop="joinUserName">
                        <el-input v-model="dataForm3.joinUserName"
                                  placeholder="请选择验收参与人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="验收意见" prop="acceptOpinion">
                        <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit
                                  v-model="dataForm3.acceptOpinion" placeholder="请填写验收意见"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="验收现场" prop="prjAs">
                        <div class="content">
                          <div
                            v-for="(item, index) in treeData5"
                            :key="item.id"
                            class="img-content"
                            @click="getNodeClick(item)"
                          >
                            <img
                              v-if="
                          item.extension == 'jpg' ||
                          item.extension == 'png' ||
                          item.extension == 'image/jpeg' ||
                          item.extension == 'image/png'
                        "
                              :src="item.link"
                              alt=""
                            />
                            <img
                              v-else
                              src="@/assets/images/dashboard-nodata.png"
                              alt=""
                            />

                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        v-dialog-drag
        title="附件预览"
        :modal="true"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        :visible.sync="showFileDialog"
        width="70%"
    >
      <iframe
          ref="fileIframe"
          :src="fileUrl"
          frameborder="0"
          style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as  API from "@/api/riskitemView/acceptance";
import website from "@/config/website";
import {detail} from "@/api/riskitemView/checklist";
import {HD_SOURCE_TYPE} from "@/api/riskitemView/evaluate";
import snows from "@/util/snows";

export default {
  data() {
    return {
      fileUrl:'',
      deptShow: false,
      showFileDialog: false,
      historyShow: false,
      modelDataForm: {},
      modelDataFormImgList: [],
      dataForm1: {},
      dataForm2: {},
      dataForm3: {},
      treeData: [],
      treeData1: [],
      treeData2: [],
      treeData3: [],
      treeData4: [],
      treeData5: [],
      sourceDic: [],
      hd_level: [],
      historyList: [],
      HD_SOURCE_TYPE: [],
      hd_type: [],
      formType: '',
      headTitle: '',
      mapBox: false,
      mapdata: '',
      dataForm: {},
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      hd_status: [],
      selectionList: [],
      active: 1,
      hdStatus:'',
      prepare: false,//草稿
      unassess: false,//待评估
      unrectify: false,//待整改
      finished: false,//完成
    }
  },
  computed: {},
  mounted() {
    this.dataForm.id = this.$route.query.id;
    this.getPrjbasicinfoDetail();
    this.getCode('HD_SOURCE_TYPE')
    this.getCode('hd_status')
    this.getCode('hd_level')
    this.getCode('hd_type')
  },
  methods: {
    handlePreview(file){
      snows.downloadFile1(file.link,file.name)
    },
    hdStatusShow() {
      if (this.hdStatus == 'prepare') {
        this.prepare = true
        this.active = 1
      } else if (this.hdStatus == 'unassess') {
        this.prepare = true
        // this.unassess = true
        this.active = 1
      } else if (this.hdStatus == 'canceled') {
        this.prepare = true
        this.unassess = true
        this.active = 1
      } else if (this.hdStatus == 'unrectify') {
        this.prepare = true
        this.unassess = true
        // this.unrectify = true
        this.active = 2
      } else if (this.hdStatus == 'unaccept') {
        this.prepare = true
        this.unassess = true
        this.unrectify = true
        // this.finished = true
        this.active = 3
      } else if (this.hdStatus == 'finished') {
        this.prepare = true
        this.unassess = true
        this.unrectify = true
        this.finished = true
        this.active = 4
      }
    },
    getCode(code) {
      API.HD_SOURCE_TYPE(code).then(res => {
        this[code] = res.data.data
      })
    },

    handleAvatarSuccess1(res, file, fileList) {
      this.treeData5.push({
        extension: file.raw.type,
        id: file.response.data.attachId,
        link: file.response.data.link,
        name: file.response.data.originalName,
      });
    },
    checkFileType(file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.'));
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isJPG = (fileType === '.jpg' || fileType == '.png' || fileType == '.mp4');

      if (!isJPG) {
        this.$message.warning('上传图片只能是 .jpg  .png .mp4 格式!');
      }
      if (!isLt20M) {
        this.$message.warning('上传文件大小不能超过 20MB!');
      }
      return isJPG && isLt20M;
    },
    checkFileType1(file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.'));
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isJPG = (fileType === '.mp4' || fileType == '.png' || fileType == '.jpg');

      if (!isJPG) {
        this.$message.warning('上传图片只能是 .png  .jpg .mp4格式!');
      }
      if (!isLt20M) {
        this.$message.warning('上传文件大小不能超过 20MB!');
      }
      return isJPG && isLt20M;
    },

    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    // 根据ID查表单详情
    getPrjbasicinfoDetail() {
      detail({
        id: this.dataForm.id
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data;
          console.log(this.dataForm);
          if(this.dataForm.hdUrl){
            this.treeData=JSON.parse(this.dataForm.hdUrl)
          }

          if (res.data.data.hdAssessmentVO) {
            this.dataForm1 = res.data.data.hdAssessmentVO;
          }
          if (res.data.data.hdRectifyVO) {
            this.dataForm2 = res.data.data.hdRectifyVO;
          }
          if (res.data.data.hdAcceptVO) {
            this.dataForm3 = res.data.data.hdAcceptVO;
          }
          if (res.data.data.hdAssessmentVO.assessmentUrl) {
            this.treeData1 = JSON.parse(res.data.data.hdAssessmentVO.assessmentUrl)
          }
          if (res.data.data.hdAssessmentVO.punishUrl) {
            this.treeData2 = JSON.parse(res.data.data.hdAssessmentVO.punishUrl)
          }
          if (res.data.data.hdRectifyVO.rectifyPlanUrl) {
            this.treeData3 = JSON.parse(res.data.data.hdRectifyVO.rectifyPlanUrl)
          }
          if (res.data.data.hdRectifyVO.rectifyUrl) {
            this.treeData4 = JSON.parse(res.data.data.hdRectifyVO.rectifyUrl)
          }
          if (res.data.data.hdAcceptVO.acceptUrl) {
            this.treeData5 = JSON.parse(res.data.data.hdAcceptVO.acceptUrl)
          }
          if(this.dataForm.hdStatus){
            this.hdStatus = this.dataForm.hdStatus || 'prepare'
          }
          console.log(this.hdStatus,'this.hdStatus');
          this.hdStatusShow()
        }
      })
      API.List({
        hdId: this.dataForm.id,
        size: 9999,
        current: 1,
      }).then(res => {
        this.historyList = res.data.data.records
      })
    },
    getNodeClick(node) {
      this.selectNode = node;
      this.showFile(node);
    },
    handleRemove(item, index) {
      this.treeData.splice(index, 1);
    },
    showFile(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL+ "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
  }
}

</script>
<style lang="scss" scoped>
.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
}

.step-box {
  background-color: #ffffff;
  width: 125px;
}

.form-box {
  background-color: #ffffff;
  width: calc(100% - 135px);
  height: 100%;
  overflow: scroll;
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

.shuttleBackBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;

  .el-tabs {
    width: 100%;
  }

  .table-box {
    width: calc(100% - 280px);
  }
}

.shutleBack {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.leftRightBtn {
  margin: 211px 12px;
}

.checklist-edit {
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}

.shutContent {
  padding: 6px;
  height: 400px;
  overflow: auto;
}

.deptBox {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: flex;
}

.unitBox {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}

.unitTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}

.content {
  display: flex;
  align-items: center;

  .img-content {
    width: 188px;
    height: 100px;
    padding: 3px;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 12px;

    .delete-icon {
      position: absolute;
      top: -6px;
      right: -6px;
      font-size: 16px;
      color: red;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

}
</style>
